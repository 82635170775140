<template>
  <div class="flex items-stretch h-screen">
    <div
      v-show="!$store.getters.isFullscreen && $store.getters.isAuthenticated"
      id="sidebar"
      class="w-12 bg-gray-800 flex flex-col justify-between"
    >
      <div class="h-12 flex justify-center items-center">
        <img
          class="w-8 h-8"
          src="@/assets/logo-color.png"
          alt=""
        >
      </div>
      <ul>
        <li class="my-16 cursor-pointer">
          <a
            class="flex justify-center"
            @click="$router.push('/dashboard/map')"
          >
            <i
              :class="['map', 'cameras'].includes($store.getters.getCurrentModule.module) ? 'text-blue-600' : 'text-gray-700'"
              class="ri-home-4-line  hover:text-white block ri-lg"
            />
          </a>
        </li>
        <li
          v-show="$store.getters.getCurrentAuthUser && $store.getters.getCurrentAuthUser.data.role_id.name !== 'Guest'"
          class="my-16 cursor-pointer"
        >
          <a
            class="flex justify-center"
            @click="$router.push('/dashboard/data')"
          >
            <i
              :class="$store.getters.getCurrentModule.module === 'data' ? 'text-blue-600' : 'text-gray-700'"
              class="ri-folder-line  hover:text-white block ri-lg"
            />
          </a>
        </li>
      </ul>
      <div />
      <!-- <el-menu default-active="2" class="el-menu-vertical-demo" :collapse="true">
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span slot="title">Navigator One</span>
          </template>
          <el-menu-item-group>
            <span slot="title">Group One</span>
            <el-menu-item index="1-1">item one</el-menu-item>
            <el-menu-item index="1-2">item two</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group title="Group Two">
            <el-menu-item index="1-3">item three</el-menu-item>
          </el-menu-item-group>
          <el-submenu index="1-4">
            <span slot="title">item four</span>
            <el-menu-item index="1-4-1">item one</el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-menu-item index="2">
          <i class="el-icon-menu"></i>
          <span slot="title">Navigator Two</span>
        </el-menu-item>
        <el-menu-item index="3" disabled>
          <i class="el-icon-document"></i>
          <span slot="title">Navigator Three</span>
        </el-menu-item>
        <el-menu-item index="4">
          <i class="el-icon-setting"></i>
          <span slot="title">Navigator Four</span>
        </el-menu-item>
      </el-menu> -->
    </div>
    
    <div class="w-full flex flex-col">
      <div
        v-show="!$store.getters.isFullscreen"
        id="header"
        class="w-full h-12 py-4 bg-gray-800 flex justify-between items-center pl-4"
      >
        <h1 class="text-white text-sm">
          <span
            class="mr-4 mt-4 cursor-pointer"
            @click="collapse"
          >
            <i class="ri-menu-line" />
          </span>
          System Monitoring CCTV Kota Malang
        </h1>
        
        <div
          v-if="$store.getters.getCurrentAuthUser"
          class="flex flex-row items-center"
        >
          <el-radio-group
            v-show="$store.getters.getCurrentModule.module !== 'data'"
            v-model="$store.getters.getCurrentModule.module"
            fill="#ffffff"
            text-color="#1989FA"
            size="mini"
            class="mr-4"
            @input="onHomeChange"
          >
            <el-radio-button label="map">
              <i class="ri-map-pin-line" />
            </el-radio-button>
            <el-radio-button label="cameras">
              <i class="ri-vidicon-line" />
            </el-radio-button>
          </el-radio-group>
          <el-dropdown>
            <el-button class="h-12 ">
              <span class=" mr-2">
                {{ $store.getters.getCurrentAuthUser.data.full_name }} ({{ $store.getters.getCurrentAuthUser.data.role_id.name }})
              </span>
              <i class="el-icon-arrow-down" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-user">
                Edit Profil
              </el-dropdown-item>
              <el-dropdown-item icon="el-icon-setting">
                Pengaturan
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button-group>
            <!-- <el-dropdown>
              <el-button class="h-12 ">
                <span class=" mr-2">Yuri Pratama</span>
                <i class="el-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-user">Edit Profil</el-dropdown-item>
                <el-dropdown-item icon="el-icon-setting">Pengaturan</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
            <el-button
              class="h-12"
              @click="maximize"
            >
              <!-- <el-badge is-dot class="item"> -->
              <!-- <i class="ri-notification-line"></i> -->
              <i class="ri-fullscreen-line" />
              <!-- </el-badge> -->
            </el-button>
            <el-button
              class="h-12"
              @click="doLogout"
            >
              <!-- <i class="ri-logout-circle-line"></i> -->
              <i class="ri-shut-down-line" />
            </el-button>
          </el-button-group>
          <!-- <a href="" class="h-12 -mr-1 flex items-center text-white px-4  text-sm">Yuri</a> -->
          <!-- <a href="" class="h-12 flex items-center text-white px-4  text-sm">Pratama</a> -->
          <!-- <a href="" class="h-12 flex items-center text-white px-4  text-sm">Yuri Pratama</a> -->
        </div>
      </div>
      <!-- <div id="content" ref="map" class="w-full h-full bg-black"></div> -->
      <div
        id="content"
        class="w-full h-full"
        @keydown.esc="restore"
      >
        <router-view />
        <!-- <div class="flex h-full">
          <div class="w-1/5 bg-gray-800">
            <ul>
              <li class="cursor-pointer text-gray-500 hover:text-white flex justify-between items-center px-4 py-2">
                <i class="ri-eye-line cursor-pointer"></i>
                <div class="text-left">
                  <span class="block text-sm">CCTV 001</span>
                  <span class="block text-xs">Jalan Basuki Rachmat</span>
                </div>
                <i class="ri-edit-box-line"></i>
              </li>
            </ul>
          </div>
          <div class="w-4/5">mainbar</div>
        </div> -->
        <!-- <el-table
    :data="table"
    stripe
    style="width: 100%">
    <el-table-column
      prop="date"
      label="Date"
      width="180">
    </el-table-column>
    <el-table-column
      prop="name"
      label="Name"
      width="180">
    </el-table-column>
    <el-table-column
      prop="address"
      label="Address">
    </el-table-column>
  </el-table> -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      table: [{
          date: '2016-05-03',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
        }, {
          date: '2016-05-02',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
        }, {
          date: '2016-05-04',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
        }, {
          date: '2016-05-01',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
        }],
      mode: 'map'
    }
  },
  async created() {
    document.addEventListener('keyup', (evt) => {
      if (evt.keyCode === 27) {
        this.restore();
      }
    });
  },
  methods: {
    doLogout() {
      this.$confirm('Do you want to logout?', 'Warning', {
        confirmButtonText: 'Logout',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('DO_LOGOUT').then(() => {
          this.$message({
            type: 'success',
            message: 'Logout Success!'
          });
          this.$router.push('/auth')
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: 'Delete canceled'
        // });
      });
    },
    onHomeChange(val) {
      this.$router.push(`${val !== 'cameras' ? '/dashboard' : ''}/${val}`);
      // console.log(val)
    },
    maximize() {
      this.$store.dispatch('MAXIMIZE');
      this.$alert('Press ESC to exit fullscreen', 'Information', {
        confirmButtonText: 'OK',
      });
    },
    collapse() {
      this.$store.dispatch('TOGGLE_COLLAPSE');
    },
    restore() {
      this.$store.dispatch('MINIMIZE');
    }
  }
}

</script>  

<style lang="css">
.el-radio-button:first-child .el-radio-button__inner {
  @apply rounded-tl-full rounded-bl-full
}
.el-radio-button:last-child .el-radio-button__inner {
  @apply rounded-tr-full rounded-br-full
}
.el-radio-button__inner {
  background: transparent;
  @apply text-white
}
#sidebar {
  border-right: 1px solid;
  @apply border-gray-700;
}
#header {
  border-bottom: 1px solid;
  @apply border-gray-700 !important;
}
#header a {
  border-right: 1px solid;
  border-left: 1px solid;
  @apply border-gray-700;
}
.el-dropdown .el-button {
  border-right: none;
}
.el-dropdown .el-button:hover,
.el-button-group .el-button:hover {
  @apply text-white;
}

.el-dropdown .el-button,
.el-button-group .el-button {
  @apply rounded-none text-gray-600 border-gray-700;

  background: transparent;
  border-top: none;
  border-bottom: none;
}
.el-radio-group .el-radio-button__inner,
.el-radio-button:first-child .el-radio-button__inner {
  border-color: white;
}
.el-button-group .el-button:last-child {
  border-right: none;
}
</style>